<template>
  <div class="mt-2">
    <div v-for="(group, gindex) of groupedDataRows" :key="gindex" elevation="3" outlined style="position:relative">
      <v-card-text class="pa-1 px-2">
        <v-card class="sticky" flat>
          <v-card-title class="pa-0">
            <h1 v-if="$store.state.articleStatus.hideFullGrouper" class="ellipsis py-3">{{ gindex === 'undefined' ? '' : gindex }}</h1>
            <h4 v-else class="py-3">{{ gindex === 'undefined' ? '' : gindex }}</h4>
          </v-card-title>
        </v-card>
        <div v-for="matrix in group" :key="matrix.SKUS[0].articleCodeSupplier">
          <swMatrixProduct :product="matrix" :readonly="true" :show-eye="true" :hide-zeroes="hideZeroes" :show-image="showImage">
            <template #left="{ leftProps }">
              <div>
                <h2>{{ leftProps.SKUS[0].articleCodeSupplier }}</h2>
                <h3>{{ leftProps.SKUS[0].articleDescription }}</h3>
              </div>
            </template>
          </swMatrixProduct>
        </div>
      </v-card-text>
    </div>
    <swScrollTopFab />
  </div>
</template>

<script>
import { articleStatus } from '@softwear/latestcollectioncore'
import swScrollTopFab from '../components/swScrollTopFab.vue'
import globalStore from '../store/globalStore'
import productFunctions from '../functions/products'
import swMatrixProduct from '../components/swMatrixProduct.vue'
import { eventBus } from '../main'

export default {
  components: { swScrollTopFab, swMatrixProduct },
  props: ['aggregations', 'selectedFields', 'fieldChoices', 'dates', 'selectedGroups', 'itemsPerPage', 'hideZeroes', 'showImage', 'showNegativeValues', 'showPositiveValues'],
  data() {
    return {
      fields: {},
      barcodesToFilterBy: [],
      lowerCaseFilter: '',
    }
  },
  computed: {
    groupedDataRows() {
      const groups = {}
      const grouped = this.selectedGroups.length > 0
      const groupDepth = this.selectedGroups.length
      this.dataTableRows.forEach((element) => {
        const key =
          ' ' +
          (grouped
            ? element.SKUS.find((sku) => sku.GROUPED)
                .GROUPED?.slice(0, groupDepth)
                .join(', ')
            : '')
        if (!groups[key]) groups[key] = []
        groups[key].push(element)
      })
      return groups
    },
    dataTableRows() {
      const dateRange = articleStatus.getDateRangeFromPicker(this.dates)
      const timeFrame = dateRange.timeFrame
      if (!this.aggregations) return []
      const aggregationFilter = this.decideAggregationFilterFunction()
      const inputDataForMatrices = Object.entries(this.aggregations)
        .filter((oneAggregatedRow) => oneAggregatedRow[0] != '** TOTAL **')
        .map((oneAggregatedRow) => {
          const key = oneAggregatedRow[0]
          const vector = [].slice.call(oneAggregatedRow[1]) // vector is now a normal Array instead of a Float64Array so can be extended with additional fields
          const keyFields = key.split('\t')
          const lastField = keyFields.length
          articleStatus.postAgg(vector, timeFrame)
          return {
            GROUPSTRING: key,
            GROUPED: keyFields,
            articleCode: keyFields[lastField - 3], // We forced the groupers to end with articleCode + description + barcode in reports.Vue.prepareGroupsForVisualization
            barcode: keyFields[lastField - 1],
            qty: vector[this.selectedFields[0]],
          }
        })
        .filter((oneAggregatedRow) => {
          if (this.showNegativeValues == this.showPositiveValues) return true
          if (this.showNegativeValues) return oneAggregatedRow.qty < 0
          if (this.showPositiveValues) return oneAggregatedRow.qty > 0
          return oneAggregatedRow.qty != 0
        })
        .filter(aggregationFilter)
        .sort((a, b) => (a.GROUPSTRING < b.GROUPSTRING ? -1 : 1))

      // Find out how many rows we need to render 'itemsPerPage' matrices (= articleCodes)
      let nrArticleCodesSeen = 0
      let previousIndex = 0
      let previousGroup = null
      let index = 0
      const totalNrRows = inputDataForMatrices.length
      const result = []
      while (nrArticleCodesSeen < this.itemsPerPage && index < totalNrRows) {
        const groupExpr = inputDataForMatrices[index]?.GROUPED.slice(0, -2).join('\t')
        if (groupExpr != previousGroup || index == totalNrRows - 1) {
          previousGroup = groupExpr
          const t = productFunctions.buildMatrices(
            inputDataForMatrices.slice(previousIndex, index == totalNrRows - 1 ? totalNrRows : index),
            globalStore.getLatestCollectionObject('sku'),
            globalStore.getIndexedProducts(),
            (product) => product.SKUS.find((sku) => sku.GROUPED)?.GROUPED?.slice(-3, -1),
            globalStore.getMetadata().sizeOrdering.data,
            this.$store.getters.mapSubSizes,
            'colorCodeSupplier',
            ['']
          )
          if (t[0] && this.hasData(t[0])) {
            nrArticleCodesSeen++
            result.push(t[0])
          }
          previousIndex = index
        }
        index++
      }
      return result
    },
  },
  created() {
    eventBus.$on('updateVisualizations', () => {
      // The order in which to run the computed properties is important, it needs to follow the dependency chain
      this._computedWatchers.dataTableRows.run()
      this._computedWatchers.groupedDataRows.run()
      this.$forceUpdate()
    })
    this.fields = this.fieldChoices.reduce((agg, item) => {
      agg[item.value] = item.text
      return agg
    }, {})
  },
  beforeDestroy() {
    eventBus.$off('updateVisualizations')
  },
  methods: {
    hasData(matrix) {
      if (!matrix) return false
      const firstRowWithData = matrix.MATRIX.rows.find((row) => row.cells.find((cell) => cell.value !== 0))
      return !!firstRowWithData
    },
    getBarcodesToFind(value) {
      // Return a list with the possible barcodes to filter based on it
      const barcodes = []
      const prefix = value.substring(0, 12)
      // Generate a list of barcodes by changing the last digit in range 0 to 9
      if (value.length == 13) for (let i = 0; i <= 9; i++) barcodes.push(prefix + `${i}`)
      // Generate a list of barcodes that contains the given 12 digits barcode and the same given barcode begins with extra 0
      else barcodes.push(value, `0${value}`)
      return barcodes
    },
    barcodeFilterFunction(oneAggregatedRow) {
      return this.barcodesToFilterBy.some((barcode) => oneAggregatedRow.barcode == barcode)
    },
    fuzzyFilterFunction(oneAggregatedRow) {
      return !this.lowerCaseFilter || oneAggregatedRow?.GROUPSTRING.toLowerCase().includes(this.lowerCaseFilter)
    },
    decideAggregationFilterFunction() {
      // Decides weither we need to filter for a barcode, or another value
      this.lowerCaseFilter = this.$store.state.filter?.toLowerCase() || ''
      if (Number.parseInt(this.lowerCaseFilter) && [12, 13].includes(this.lowerCaseFilter.length)) {
        this.barcodesToFilterBy = this.getBarcodesToFind(this.lowerCaseFilter)
        return this.barcodeFilterFunction
      }
      return this.fuzzyFilterFunction
    },
  },
}
</script>
